import React, { useEffect, useState } from "react";
import { 
  useDispatch,
  useSelector, 
} from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import moment from "moment";
import { AvField, AvForm } from "availity-reactstrap-validation";
import CustomPagination from "components/Common/CustomPagination";
import AvFieldSelect from "components/Common/AvFieldSelect";
import Loader from "components/Common/Loader";
import TableLoader from "components/Common/TableLoader";
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from "react-super-responsive-table";
import { 
  Button, Col, Row
} from "reactstrap";
import { fetchMamProfiles } from "store/mam-pam/actions";
import { getFeesDeductedAPI } from "apis/mam-profile";
const getFeeName = (feeType) => {
  switch (feeType) {
    case 1: return "Daily Management Fee";
    case 2: return "Weekly Management Fee";
    case 3: return "Monthly Management Fee";
    case 4: return "Quarterly Management Fee";
    case 6: return "Yearly Management Fee";
    case 7: return "Management Fee";
    case 8: return "Per Lot Fee";
    case 9: return "Performance Fee";
    case 10: return "IB Profit Share";
    default: return "UNKNOWN";
  }
};

export default function SPStatement() {
  const { t } = useTranslation();
  const columns = [
    {
      dataField: "recordId",
      text: t("Id")
    },
    {
      dataField: "name",
      text: t("Investor Login"),
      formatter: (obj) => {
        return obj?.investorAccountId?.login;
      }
    },
    {
      dataField: "name",
      text: t("Name"),
      formatter: (obj) => {
        return (
          <strong className="text-capitalize">{`${obj?.investorAccountId?.customerId?.firstName} ${obj?.investorAccountId?.customerId?.lastName}`}</strong>
        );
      }
    },
    {
      dataField: "feeType",
      text: t("Fee Type"),
      formatter: (obj) => {
        return getFeeName(obj?.feeType);
      }
    },
    {
      dataField: "amount",
      text: t("Amount"),
      formatter: (obj) => {
        return obj?.amount?.toFixed(4);
      }
    },
    {
      dataField: "createdAt",
      text: t("Created At"),
      formatter: (obj) => {
        return moment(obj?.createdAt).format("YYYY-MM-DD HH:mm:ss");
      }
    },
  ];
  const dispatch = useDispatch();
  const { _id } = useSelector(
    (state) => state.clientReducer?.clientDetails
  );
  const { profiles, profileLoading } = useSelector((state) => ({
    profiles: state.mamPamReducer.profile?.profiles,
    profileLoading: state.mamPamReducer.profile?.loading,
  }));
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    docs: [],
    pagination: {},
  });
  const [sizePerPage, setSizePerPage] = useState(10);
  const [profile, setProfile] = useState(null);
  const [dateFrom, setDateFrom] = useState(String(moment().subtract(30, "days").format("YYYY-MM-DD")));
  const [dateTo, setDateTo] = useState(String(moment().add(1, "day").format("YYYY-MM-DD")));
  const handleDateFrom = (e) => setDateFrom(e.target.value);
  const handleDateTo = (e) => setDateTo(e.target.value);
  const loadStatement = (page, limit) => {
    setLoading(true);
    const payload = {
      page,
      limit,
      profileId: profile._id,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    getFeesDeductedAPI(payload).then((res) => {
      if (res?.isSuccess) {
        setPayload({
          docs: res?.result?.docs,
          pagination: {
            ...res?.result
          }
        });
      } else throw new Error(res?.message);
    }).catch((err) => {
      console.log(err);
      setPayload({
        docs: [],
        pagination: {},
      });
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    dispatch(fetchMamProfiles({
      customerId: _id,
      page: 1,
      limit: 100,
    }));
  }, [_id]);

  useEffect(() => {
    profile && loadStatement(1, sizePerPage);
  }, [sizePerPage]);

  return (
    <Card className="p-3 mt-4">
      <div className="d-flex justify-content-between">
        <h3>{t("Statement")}</h3>
        <i className="bx bx-dots-vertical-rounded fs-3 mt-1"></i>
      </div>
      <AvForm onValidSubmit={(e, data) => loadStatement(1, 10)}>
        <Row className="mt-3 justify-content-start align-items-center gap-5">
          <Col xs="12" md="3" lg="2">
            <AvFieldSelect 
              className="mt-1 form-select" 
              name="accountTypeId"
              label={t("Profile")}
              errorMessage={t("Profile is required")}
              validate={{ required: { value: true } }}
              options={profiles?.map((profile) => {
                return {
                  label: `${profile.profileType} | ${profile.masterTradingAccountId?.login} | ${profile.slaves?.length} Members`,
                  value: profile,
                };
              })}
              value={profile}
              onChange={e => {
                setProfile(e);
              }}
            />
          </Col>
          <Col xs="12" md="3" lg="2" className="mb-2">
            <AvField 
              className="mt-1 mb-2"
              type="date" 
              name="dateFrom" 
              label={t("From Date")}
              value={dateFrom}
              validate={ { date: { format: "YYYY-MM-DD" } }} 
              onChange={handleDateFrom}
            />
          </Col>
          <Col xs="12" md="3" lg="2" className="mb-2">
            <AvField 
              className="mt-1 mb-2"
              type="date" 
              name="dateTo" 
              label={t("To Date")}
              validate={ { date: { format: "YYYY-MM-DD" } }} 
              value={dateTo}
              onChange={handleDateTo}
            />
          </Col>
          <Col xs="12" md="3" lg="2">
            <Button
              className="btn-light mt-3 mt-lg-0"
              color="primary"
              disabled={loading || !profile || profileLoading}
            >
              {loading ? <Loader/> : t("Search")}
            </Button>
          </Col>
          
        </Row>
        {/* <Row>
          <div className="mb-3 d-flex justify-content-center">
            <Button
              className="btn-light mt-3 mt-lg-0"
              color="primary"
              disabled={loading || !profile || profileLoading}
            >
              {loading ? <Loader/> : t("Search")}
            </Button>
          </div>
        </Row> */}
      </AvForm>
      <hr className="my-4" />
      {
        <div className="table-rep-plugin">
          <div
            className="table-responsive mb-0"
            data-pattern="priority-columns"
          >
            <Table
              id="tech-companies-1"
              className="table table-hover table-clients"
            >
              <Thead className="text-center table-light" >
                <Tr>
                  {columns.map((column, index) =>
                    <Th data-priority={index} className="color-primary" key={index}>{column.text}</Th>
                  )}
                </Tr>
              </Thead>
              
              <Tbody className="text-center" style={{ fontSize: "13px" }}>
                {loading && <TableLoader colSpan={4} />}
                {!loading && payload && payload.docs.map((row, rowIndex) =>
                  <Tr key={rowIndex} >
                    {columns.map((column, index) =>
                      <Td key={`${rowIndex}-${index}`}>
                        
                        {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                      </Td>
                    )}
                  </Tr>
                )}
              </Tbody>
            </Table>
            <CustomPagination
              {...payload.pagination}
              setSizePerPage={setSizePerPage}
              sizePerPage={sizePerPage}
              onChange={loadStatement}
              docs={payload && payload.docs || []}
            />
          </div>
        </div>
      }
    </Card>
  );
}
